import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import { Tooltip, Popover, Select, Empty, Table } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import CustomerContactDetailsSlider from '../Customers/CustomerContactDetailsSlider';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default function CustomerDetailSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        supportFieldsLoaded: false,
        selectedCustomerId: props.selectedCustomerId,
        sliderTitle: '',
        isDirty: false,
        showContactDetailsSlider: false,
        contactEditMode: 'Add',
        selectedItemId: null
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [contacts, setContacts] = useState([]);
    const { register, getValues, setValue, control, watch } = useForm();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const { Option } = Select;
    const navigate = useNavigate();
    const [viewOnly, setViewOnly] = useState(userInfo.userType === 'admin' ? false : true);

    useEffect(() => {
        if (props.selectedCustomerId !== null) {
            LoadExhibitorInfo();
        }
        else {
            LoadSupportFieldsOnly()
        }
    }, [props]);

    //useEffect(() => {
    //    if (state.supportFieldsLoaded) {
    //        if (props.selectedCustomerId !== null) {
    //            LoadExhibitorInfo();
    //        }
    //        else {
    //            setState({
    //                ...state,
    //                dataLoaded: true,
    //                sliderTitle: 'Add New Exhibitor',
    //                showPane: props.showPane
    //            });
    //        }
    //    }
    //}, [state.supportFieldsLoaded]);

    function LoadExhibitorInfo() {
        //console.log(props.selectedCustomer);

        //let exhibitor = props.selectedCustomer;
        //setValue("formFields.exhibitor", exhibitor);

        //setContacts(props.selectedCustomer.contacts);

        let postdata = {};

        postdata.company_id = props.selectedCustomerId;
        postdata.jobshopdivision = userInfo.jobShopDivision;

        Axios.post(`/api/GetCustomerById`, postdata
        ).then(response => {
            console.log(response.data);

            //Init Customer fields 
            let exhibitor = response.data.customer;
            let contacts = response.data.contacts;
            let salesreps = response.data.salesreps;
            let categories = response.data.categories;
            let qccerts = response.data.qccerts;
            let prospectsources = response.data.prospectsources;
            let states = response.data.states;

            setValue("formFields.exhibitor", exhibitor);
            setContacts(contacts);
            setValue("formFields.salesreps", salesreps);
            setValue("formFields.categories", categories);
            setValue("formFields.qccerts", qccerts);
            setValue("formFields.prospectsources", prospectsources);
            setValue("formFields.states", states);

            //this sets original values to use upon save to see if anything was changed
            setValue("formFields.originalexhibitor", exhibitor);

            if (exhibitor.acH_On_File) {
                setValue("formFields.exhibitor.acH_On_File", '1');
                setValue("formFields.originalexhibitor.acH_On_File", '1');
            }
            else {
                setValue("formFields.exhibitor.acH_On_File", '0');
                setValue("formFields.originalexhibitor.acH_On_File", '0');
            }

            setState({
                ...state,
                dataLoaded: true,
                sliderTitle: 'Customer Details - ' + props.selectedCustomer.name + ' (Customer ID: ' + props.selectedCustomer.id + ')',
                showPane: props.showPane
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function LoadSupportFieldsOnly() {
        let postdata = {};
        postdata.jobshopdivision = userInfo.jobShopDivision

        Axios.post(`/api/GetJSSSearchFilters`, postdata
        ).then(response => {
            let salesreps = response.data.salesreps;
            let categories = response.data.servicecategories;
            let qccerts = response.data.qccerts;
            let prospectsources = response.data.prospectsources;
            let states = response.data.states;

            setValue("formFields.salesreps", salesreps);
            setValue("formFields.categories", categories);
            setValue("formFields.qccerts", qccerts);
            setValue("formFields.prospectsources", prospectsources);
            setValue("formFields.states", states);

            setState({
                ...state,
                dataLoaded: true,
                sliderTitle: 'Add New Exhibitor',
                showPane: props.showPane
            });

        }).catch(error => {
            console.log(error);
        });
    }

    var currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    function handlePaneClose() {
        if (JSON.stringify(getValues().formFields.exhibitor) !== JSON.stringify(getValues().formFields.originalexhibitor)) {
            confirm({
                title: "Changes made to this exibitor will be lost",
                content: "Are you sure you would like continue?",
                buttons: ["Yes", "No"],
            }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    hidePane();

                    return 0;
                } else {
                    return 0;
                }
            });
        }
        else {
            hidePane();
        }
    }

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    function handleSalesRepChange(value) {
        setValue('formFields.exhibitor.jsS_sales_rep', value);
        setState({ ...state });
    }

    function handleACHOnFileChange(value) {
        setValue('formFields.exhibitor.acH_On_File', value);
        setState({ ...state });
    }

    function handleStatesChange(value) {
        setValue('formFields.exhibitor.state', value);
        setState({ ...state });
    }

    function handleSaveExhibitor(value) {
        //console.log(getValues().formFields.originalexhibitor);
        //console.log(getValues().formFields.exhibitor);

        if (screenDataOK() === false) {
            return;
        }

        //reset so dirty no longer gets triggered
        setValue("formFields.originalexhibitor", getValues().formFields.exhibitor);

        if (getValues().formFields.exhibitor.master_company_id === '') { setValue('formFields.exhibitor.master_company_id', null); }
        if (getValues().formFields.exhibitor.acH_On_File === '0') {
            setValue("formFields.exhibitor.acH_On_File", false);
        }
        else {
            setValue("formFields.exhibitor.acH_On_File", true);
        }

        let postdata = {};

        postdata.Customer = getValues().formFields.exhibitor;
        postdata.CustomerContacts = contacts;

        if (state.selectedCustomerId) {
            Axios.post('/api/UpdateCustomer', postdata
            ).then(async response => {
                setTimeout(() => props.refreshCustomer(getValues().formFields.exhibitor), 1000);

                hidePane();
                toast.success('Exhibitor successfully updated');

            }).catch(error => {
                console.log(error);
                toast.error('Something went wrong');
            });
        }
        else {
            //set exhibitor indicator
            setValue("formFields.exhibitor.exhibitor", 1);

            Axios.post('/api/InsertNewExhibitor', postdata
            ).then(async response => {
                var responseStr = response.data.toString();

                if (responseStr.includes('Exhibitor Not Added')) {
                    toast.error(responseStr);
                }
                else {
                    toast.success('New exhibitor added successfully');
                    navigate("/customerview", { state: { selectedCustomerId: responseStr, callingComponent: 'AddExhibitor' } });
                }
            }).catch(error => {
                console.log(error);
                toast.error('Something went wrong');
            });
        }
    }

    function validateCurrency(value) {
        // Simple validation for positive numbers with up to two decimal places
        const regex = /^\d+(\.\d{0,2})?$/;
        return regex.test(value);
    }

    function validateNumber(value) {
        // Simple validation for numbers and optional comma and period
        const regex = /^[0-9.,]*$/;
        return regex.test(value);
    }

    function screenDataOK() {
        let message = '';

        //mandatory fields check
        if (getValues().formFields.exhibitor.name === '') {
            message += '&bull;&nbsp;A Company Name must be provided</br>';
        }
        if (getValues().formFields.exhibitor.street1 === '') {
            message += '&bull;&nbsp;A Street Address must be provided</br>';
        }
        if (getValues().formFields.exhibitor.city === '') {
            message += '&bull;&nbsp;A City must be provided</br>';
        }
        if (getValues().formFields.exhibitor.state === '' || getValues().formFields.exhibitor.state === undefined) {
            message += '&bull;&nbsp;A State must be provided</br>';
        }
        if (getValues().formFields.exhibitor.zip === '') {
            message += '&bull;&nbsp;A Zip Code must be provided</br>';
        }

        //numeric fieldcheck for optional fields if provided
        if (getValues().formFields.exhibitor.master_company_id !== '' && getValues().formFields.exhibitor.master_company_id !== null && validateNumber(getValues().formFields.exhibitor.master_company_id) === false) {
            message += '&bull;&nbsp;\'Master Company\' must be numeric</br>';
        }
        if (getValues().formFields.exhibitor.totalemployees !== '' && getValues().formFields.exhibitor.totalemployees !== null && validateNumber(getValues().formFields.exhibitor.totalemployees) === false) {
            message += '&bull;&nbsp;\'Total Employees\' Is An Invalid Entry</br>';
        }
        if (getValues().formFields.exhibitor.startyear !== '' && getValues().formFields.exhibitor.startyear !== null && validateNumber(getValues().formFields.exhibitor.startyear) === false) {
            message += '&bull;&nbsp;\'Start Year\' Is An Invalid Entry</br>';
        }
        if (getValues().formFields.exhibitor.squarefeet !== '' && getValues().formFields.exhibitor.squarefeet !== null && validateNumber(getValues().formFields.exhibitor.squarefeet) === false) {
            message += '&bull;&nbsp;\'Square Feet\' Is An Invalid Entry</br>';
        }
        if (getValues().formFields.exhibitor.jsS_CaravanFreightAmount !== '' && getValues().formFields.exhibitor.jsS_CaravanFreightAmount !== null && validateCurrency(getValues().formFields.exhibitor.jsS_CaravanFreightAmount) === false) {
            message += '&bull;&nbsp;\'Caravan Freight Amount\' Is An Invalid Entry</br>';
        }

        ////checks to see if master company id exists if one is provided
        //if (getValues().formFields.exhibitor.master_company_id !== '' && getValues().formFields.exhibitor.master_company_id !== null && validateNumber(getValues().formFields.exhibitor.master_company_id) === true) {
        //    Axios.get(`/api/GetAdminCompanyInfo`, {
        //        params: {
        //            id: getValues().formFields.exhibitor.master_company_id
        //        }
        //    }).then(response => {
        //        console.log(response.data.length);

        //        if (response.data.length === 0) {
        //            console.log('here');
        //            message += '&bull;&nbsp;\'Master Company Id\' not found</br>';
        //        }
        //    }).catch(error => {
        //        console.log(error);
        //    });
        //}

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    function handleSelectChange(source, value) {
        setValue(source, value.toString());
        setState({ ...state });
    }

    function handleSelectClear(source) {
        setValue(source, null);
        setState({ ...state });
    }

    const columns = [
        {
            dataIndex: 'contact_id',
            title: 'contact_id',
            key: 'contact_id',
            hidden: true
        }, {
            dataIndex: 'jsS_name',
            title: 'Contact Name',
            key: 'jsS_name'
        }, {
            dataIndex: 'title',
            title: 'Title',
            key: 'title'
        }, {
            dataIndex: 'contact_type',
            title: 'Contact Type',
            key: 'contact_type'
        }, {
            title: 'Details',
            key: 'action',
            width: 75,
            render: (record) => (
                <EditOutlined className="text-center hover" style={{ fontSize: '1.2rem' }} onClick={(e) => loadContactDetails(e, record)} />
            ),
            align: 'center'
        }, {
            title: 'Delete',
            key: 'action',
            width: 75,
            render: (record) => (
                <DeleteOutlined className="text-center hover" style={{ fontSize: '1.2rem', color: 'red' }} onClick={(e) => handleDeleteContact(e, record)} />
            ),
            align: 'center',
            hidden: viewOnly
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleAddNewContact() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            contactEditMode: 'Add',
            dataLoaded: true,
            showContactDetailsSlider: true
        });
    }

    function loadContactDetails(e, record) {
        setValue('formFields.contact', record);

        const selectedIdx = contacts.findIndex(con => con.contact_id === record.contact_id);

        setState({
            ...state,
            selectedItemId: record.contact_id,
            selectedIdx,
            contactEditMode: 'Edit',
            showContactDetailsSlider: true
        });
    }

    function handleDeleteContact(e, record) {
        confirm({
            title: "You are about to permanently delete this Contact",
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                var myContacts = [...contacts]
                myContacts = myContacts.filter(contact => contact.contact_id != record.contact_id);

                setContacts(myContacts);

                toast.success('Contact deleted successfully');

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleSaveContact(contact) {
        setValue('formFields.contact', contact);

        if (state.contactEditMode === 'Add') {
            var myNextFugaziContactID = -1;

            //took this approach since we are adding the contact into the list but not into the DB yet until the 'Save' button is clicked. 
            //Setting contact_id to negative value will indicate to the API it needs to be added

            for (var i = 0; i < contacts.length; i++) {
                if (contacts[i].contact_id == myNextFugaziContactID) {
                    myNextFugaziContactID -= 1;
                }
            }
            setValue('formFields.contact.contact_id', myNextFugaziContactID);
            setValue('formFields.contact.division', "JSS");

            setContacts([
                ...contacts,
                { ...getValues().formFields.contact }
            ]);

            //setValue('formFields.contact', {});

            toast.success('New Contact added successfully');

            setState({
                ...state,
                showContactDetailsSlider: false
            });

        }
        else {
            var myContacts = [...contacts];
            myContacts = myContacts.map(c => c.contact_id !== state.selectedItemId ? c : contact);

            setContacts(myContacts);

            //setValue('formFields.contact', {});

            toast.success('Contact updated successfully');

            setState({
                ...state,
                showContactDetailsSlider: false
            });
        }

        console.log(contacts);
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-65'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title={state.sliderTitle}
                onRequestClose={handlePaneClose}
                width={'90%'}
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {state.dataLoaded &&
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    {userInfo.userType !== 'admin' &&
                                        <h3 className="frame-heading mt-3">*** View Only Mode ***</h3>
                                    }
                                    <Card className="d2pCard mb-3">
                                        <Card.Header><span>General Company Information</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="form-group col-lg-4">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" name="name" {...register("formFields.exhibitor.name")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-7"></div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Master Company</label>
                                                    <input type="text" name="name" {...register("formFields.exhibitor.master_company_id")} className="form-control-custom" disabled={viewOnly} />
                                                </div>

                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Address 1</label>
                                                    <input type="text" name="street1" {...register("formFields.exhibitor.street1")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Address 2</label>
                                                    <input type="text" name="street2" {...register("formFields.exhibitor.street2")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">City</label>
                                                    <input type="text" name="city" {...register("formFields.exhibitor.city")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">State</label>
                                                    <Select id="ddStates"
                                                        value={getValues().formFields?.exhibitor.state}
                                                        className="form-control-custom"
                                                        mode="single"
                                                        placeholder="Select State"
                                                        onChange={handleStatesChange}
                                                        className="form-control-custom w-100"
                                                        bordered={false}
                                                        disabled={viewOnly}
                                                    >
                                                        {getValues().formFields?.states.map((s) => <Option key={s.fullName} value={s.state}>{s.state}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Zip</label>
                                                    <input type="text" name="zip" {...register("formFields.exhibitor.zip")} className="form-control-custom" />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Phone</label>
                                                    <input type="text" name="phone" {...register("formFields.exhibitor.telephone")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Fax</label>
                                                    <input type="text" name="fax" {...register("formFields.exhibitor.fax")} className="form-control-custom" disabled={viewOnly} />
                                                </div>

                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Sales Rep</label>
                                                    <Select id="ddSalesReps"
                                                        value={getValues().formFields?.exhibitor.jsS_sales_rep}
                                                        className="form-control-custom"
                                                        mode="single"
                                                        placeholder="Select Sales Rep"
                                                        onChange={handleSalesRepChange}
                                                        className="form-control-custom w-100"
                                                        bordered={false}
                                                        disabled={viewOnly}
                                                    >
                                                        {getValues().formFields?.salesreps.map((rep) => <Option key={rep.id} value={rep.name}>{rep.name}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Total Employees</label>
                                                    <input type="text" name="totalemployees" {...register("formFields.exhibitor.totalemployees")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Square Feet</label>
                                                    <input type="text" name="squarefeet" {...register("formFields.exhibitor.squarefeet")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">Start Year</label>
                                                    <input type="text" name="startyear" {...register("formFields.exhibitor.startyear")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Domain</label>
                                                    <input type="text" name="domain" {...register("formFields.exhibitor.domain")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Exhibitor Video Link</label>
                                                    <input type="text" name="videoLink" {...register("formFields.exhibitor.videoLink")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Sort Code</label>
                                                    <input type="text" name="jsS_sortcode" {...register("formFields.exhibitor.jsS_sortcode")} className="form-control-custom" disabled={viewOnly} />
                                                </div>
                                                <div className="form-group col-lg-1">
                                                    <label className="form-label">ACH on File</label>
                                                <Select id="ddACH"
                                                    value={getValues().formFields?.exhibitor.acH_On_File}
                                                    className="form-control-custom"
                                                    mode="single"
                                                    placeholder="Select"
                                                    onChange={handleACHOnFileChange}
                                                    className="form-control-custom w-100"
                                                    bordered={false}
                                                    disabled={viewOnly}
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                            <div className="col-sm-12 col-md-6 col-xl-3">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Categories</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 d-inline-flex mb-2">
                                                <label className="form-label me-2">Maj Cat:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Category1} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Category1", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Category1")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Maj Category" bordered={false} allowClear disabled={viewOnly} >
                                                    {getValues().formFields?.categories.map((cat) => <Option key={cat.category} value={cat.category}>{cat.categoryName}</Option>)}
                                                </Select>

                                                {/*<Controller*/}
                                                {/*    control={control}*/}
                                                {/*    name="formFields.exhibitor.jsS_Category1"*/}
                                                {/*    render={({ field: { onChange, onBlur, value, ref } }) =>*/}
                                                {/*        <Select value={value} onChange={onChange} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="1st Category" bordered={false} allowClear>*/}
                                                {/*            {getValues().formFields?.categories.map((cat) => <Option key={cat.category} value={cat.category}>{cat.categoryName}</Option>)}*/}
                                                {/*        </Select>*/}
                                                {/*    }*/}
                                                {/*/>*/}
                                            </div>
                                            <div className="col-12 d-inline-flex mb-2">
                                                <label className="form-label me-2">2nd Cat:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Category2} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Category2", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Category2")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="2nd Category" bordered={false} allowClear disabled={viewOnly} >
                                                    {getValues().formFields?.categories.map((cat) => <Option key={cat.category} value={cat.category}>{cat.categoryName}</Option>)}
                                                </Select>
                                            </div>
                                            <div className="col-12 d-inline-flex mb-2">
                                                <label className="form-label me-2">3rd Cat:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Category3} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Category3", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Category3")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="3rd Category" bordered={false} allowClear disabled={viewOnly} >
                                                    {getValues().formFields?.categories.map((cat) => <Option key={cat.category} value={cat.category}>{cat.categoryName}</Option>)}
                                                </Select>
                                            </div>
                                            <div className="col-12 d-inline-flex mb-2">
                                                <label className="form-label me-2">4th Cat:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Category4} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Category4", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Category4")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="4th Category" bordered={false} allowClear disabled={viewOnly} >
                                                    {getValues().formFields?.categories.map((cat) => <Option key={cat.category} value={cat.category}>{cat.categoryName}</Option>)}
                                                </Select>
                                            </div>
                                            <div className="col-12 d-inline-flex mb-2">
                                                <label className="form-label me-2">5th Cat:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Category5} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Category5", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Category5")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="5th Category" bordered={false} allowClear disabled={viewOnly} >
                                                    {getValues().formFields?.categories.map((cat) => <Option key={cat.category} value={cat.category}>{cat.categoryName}</Option>)}
                                                </Select>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-2">
                                    <Card className="d2pCard mb-3">
                                        <Card.Header><span>QC Certifications</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 d-inline-flex mb-2">
                                                <label className="form-label me-2">QC 1:</label>
                                                    <Select value={getValues().formFields.exhibitor.jsS_qc1} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_qc1", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_qc1")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select QC 1" bordered={false} allowClear disabled={viewOnly} >
                                                        {getValues().formFields?.qccerts.map((cert) => <Option key={cert.qcname} value={cert.qcname}>{cert.qcname}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">QC 2:</label>
                                                    <Select value={getValues().formFields.exhibitor.jsS_qc2} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_qc2", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_qc2")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select QC 2" bordered={false} allowClear disabled={viewOnly} >
                                                        {getValues().formFields?.qccerts.map((cert) => <Option key={cert.qcname} value={cert.qcname}>{cert.qcname}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">QC 3:</label>
                                                    <Select value={getValues().formFields.exhibitor.jsS_qc3} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_qc3", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_qc3")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select QC 3" bordered={false} allowClear disabled={viewOnly} >
                                                        {getValues().formFields?.qccerts.map((cert) => <Option key={cert.qcname} value={cert.qcname}>{cert.qcname}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">QC 4:</label>
                                                    <Select value={getValues().formFields.exhibitor.jsS_qc4} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_qc4", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_qc4")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select QC 4" bordered={false} allowClear disabled={viewOnly} >
                                                        {getValues().formFields?.qccerts.map((cert) => <Option key={cert.qcname} value={cert.qcname}>{cert.qcname}</Option>)}
                                                    </Select>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-2">
                                    <Card className="d2pCard mb-3">
                                        <Card.Header><span>Floor Plan Ticker</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Line 1:</label>
                                                    <input type="text" name="floorplan_Ticker1" {...register("formFields.exhibitor.floorplan_Ticker1")} className="form-control-custom flex-fill w-unset text-truncate" disabled={viewOnly} />
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Line 2:</label>
                                                    <input type="text" name="floorplan_Ticker2" {...register("formFields.exhibitor.floorplan_Ticker2")} className="form-control-custom flex-fill w-unset text-truncate" disabled={viewOnly} />
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Line 3:</label>
                                                    <input type="text" name="floorplan_Ticker3" {...register("formFields.exhibitor.floorplan_Ticker3")} className="form-control-custom flex-fill w-unset text-truncate" disabled={viewOnly} />
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Line 4:</label>
                                                    <input type="text" name="floorplan_Ticker4" {...register("formFields.exhibitor.floorplan_Ticker4")} className="form-control-custom flex-fill w-unset text-truncate" disabled={viewOnly} />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-2">
                                    <Card className="d2pCard mb-3">
                                        <Card.Header><span>Source</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Source:&nbsp;&nbsp;&nbsp;</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Source} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Source", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Source")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select Source" bordered={false} allowClear disabled={viewOnly}>
                                                        {getValues().formFields?.prospectsources.map((src) => <Option key={src.source} value={src.source}>{src.source}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Source 2:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Source2} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Source2", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Source2")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select Source 2" bordered={false} allowClear disabled={viewOnly}>
                                                        {getValues().formFields?.prospectsources.map((src) => <Option key={src.source} value={src.source}>{src.source}</Option>)}
                                                    </Select>
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Source 3:</label>
                                                <Select value={getValues().formFields.exhibitor.jsS_Source3} onSelect={(value) => handleSelectChange("formFields.exhibitor.jsS_Source3", value)} onClear={() => handleSelectClear("formFields.exhibitor.jsS_Source3")} className="form-control-custom flex-fill w-unset text-truncate" mode="single" placeholder="Select Source 3" bordered={false} allowClear disabled={viewOnly} >
                                                        {getValues().formFields?.prospectsources.map((src) => <Option key={src.source} value={src.source}>{src.source}</Option>)}
                                                    </Select>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-sm-12 col-md-6 col-xl-3">
                                    <Card className="d2pCard mb-3">
                                        <Card.Header><span>Caravan Freight</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Amount:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                                <input type="text" name="jsS_CaravanFreightAmount" {...register("formFields.exhibitor.jsS_CaravanFreightAmount")} className="form-control-custom" style={{ "width": "30%" }} disabled={viewOnly} />
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">No. Of Pieces:</label>
                                                <input type="text" name="jsS_CaravanFreightNumberOfPieces" {...register("formFields.exhibitor.jsS_CaravanFreightNumberOfPieces")} className="form-control-custom" style={{ "width": "30%" }} disabled={viewOnly} />
                                                </div>
                                                <div className="col-12 d-inline-flex mb-2">
                                                    <label className="form-label me-2">Internal Note:</label>
                                                <input type="text" name="jsS_CaravanFreightInternalNote" {...register("formFields.exhibitor.jsS_CaravanFreightInternalNote")} className="form-control-custom flex-fill w-unset text-truncate" disabled={viewOnly} />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                            
                                <div className="form-group col-lg-5 col-12">
                                    <div className="rcorners p-2">
                                        <h3 className="frame-heading">
                                            Contacts
                                        </h3>

                                        <div className="col-sm-12 col-md-12 col-lg-12" disabled={viewOnly} >
                                            {contacts?.length === 0 ?
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                    <span>
                                                        No Contacts Added Yet For This Exhibitor
                                                    </span>
                                                } />
                                                :
                                                <Table className="custom-ant-selection"
                                                    rowKey={item => item.contact_id}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    size="small"
                                                    dataSource={contacts}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        )
                                                    }}
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.contact_id];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                loadContactDetails(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-8 mt-3">
                                                {userInfo.userType === 'admin' &&
                                                    <span style={{ "color": "red" }}>Contact Changes Will Be Applied When Exhibitor Is Saved</span>
                                                }
                                            </div>
                                            <div className="form-group col-4 text-end">
                                                {userInfo.userType === 'admin' &&
                                                    <button className="btn btn-submit mt-3" onClick={handleAddNewContact}>Add New Contact</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                               
                                </div>
                                <div className="col-lg-7 col-12">
                                    <Card className="d2pCard mb-3">
                                        <Card.Header><span>Company Description</span></Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="form-group col-lg-12">
                                                <textarea name="description" cols="40" rows="8" {...register("formFields.exhibitor.description")} className="form-control-custom" style={{ resize: 'none' }} disabled={viewOnly} />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        {userInfo.userType === 'admin' &&
                            <button className="btn btn-submit me-3 float-right" onClick={handleSaveExhibitor}>Save</button>
                        }
                        <button className="btn btn-outline-primary margin-left-15 float-right" onClick={handlePaneClose}>Close</button>
                        {userInfo.userType !== 'admin' &&
                            <span className="frame-heading mt-3 ms-3">*** View Only Mode ***</span>
                        }
                    </div>
                </div>

            </SlidingPane>

            {state.showContactDetailsSlider &&
                <CustomerContactDetailsSlider showPane={state.showContactDetailsSlider} mode={state.contactEditMode} callingComponent='ExhibitorDetailsSlider' contacts={contacts} selectedIdx={state.selectedIdx} selectedItemId={state.selectedItemId}
                    handleSaveContact={handleSaveContact} selectedCustomerId={props.selectedCustomerId} hidePane={() => setState({ ...state, showContactDetailsSlider: false })} />
            }
        </>
    );
}