import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
/*import { Button, Modal as NotesModal, Modal as WorkFlowModal, Modal as TasksModal } from 'react-bootstrap';*/
import { toast, confirm } from '@rickylandino/react-messages';
/*import Modal from 'react-modal';*/
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { useForm, Controller } from "react-hook-form";
import { Fragment } from 'react';
import { useNavigate, useLocation, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Tabs, Spin, Alert, Popover, Modal, Button } from 'antd';
import CustomerContacts from './CustomerContacts';
import CustomerNotes from './CustomerNotes';
import ShowDetailInfo from './ShowDetailInfo';
import AdvertisingInfo from './AdvertisingInfo';
import CustomerTasks from './CustomerTasks';
import JSS_SalesOrderWFSlider from '../Workflow/JSS_SalesOrderWFSliderV2';
import JSS_ExhibitorChangeWFSlider from '../Workflow/JSS_ExhibitorChangeWFSlider';
import JST_AdvertiserChangeWFSlider from '../Workflow/JST_AdvertiserChangeWFSlider';
import JST_RequestAdWFSlider from '../Workflow/JST_RequestAdWFSlider';
import SendEmailSlider from '../Email/SendEmailSlider';
import CustomerDetailSlider from './CustomerDetailSlider';

export default function CustomerView(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const { TabPane } = Tabs;

    const [state, setState] = useState({
        selectedCustomerId: props.selectedCustomerId,
        callingComponent: '',
        dataLoaded: false,
        selectedCustomer: {},
        selectedWFType: '',
        showSalesOrderWFSlider: false,
        showExhibitorChangeWFSlider: false,
        showAdvertiserChangeWFSlider: false,
        showShowDetailSliderVO: false,
        showAdvertisingInfoSliderVO: false,
        showNoteDetailsSlider: false,
        showRequestAdWFSlider: false,
        showSendEmailSlider: false,
        showCustomerDetailSlider: false,
        customerFound: false,
        showsStatusText: '',
        showsStatusDivStyle: '',
        advertisingStatusText: '',
        advertisingStatusDivStyle: '',
        webStatusText: '',
        webStatusDivStyle: '',
        repplaceStatusText: '',
        repplaceStatusDivStyle: '',
        prospectConversionType: '',
        selectedContact: {},
        emailInformation: {}
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [contactsListData, setContactsListData] = useState([]);

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [idStatusModal, setIdStatusModal] = useState({
        show: false
    });
    const [showWorkflowModal, setShowWorkflowModal] = useState(false);

    const [workflowOpen, setWorkflowOpen] = useState(false);

    const hide = () => {
        setWorkflowOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setWorkflowOpen(newOpen);
    };

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        setState({
            ...state,
            selectedCustomerId: location.state.selectedCustomerId,
            callingComponent: location.state.callingComponent,
            dataLoaded: false
        });

        LoadCustomerInfo(location.state.selectedCustomerId, userInfo.jobShopDivision);
    }, [props]);

    function LoadCustomerInfo(id, jobshopdivision) {
        let myShowsStatusDivStyle = '';
        let myShowsStatusText = '';
        let myAdvertisingStatusDivStyle = '';
        let myAdvertisingStatusText = '';
        let myWebStatusDivStyle = '';
        let myWebStatusText = '';
        let myRepplaceStatusDivStyle = '';
        let myRepplaceStatusText = '';

        let postdata = {};

        postdata.company_id = id;
        postdata.jobshopdivision = jobshopdivision;

        Axios.post(`/api/GetCustomerById`, postdata
        ).then(response => {
            //Init Customer fields 
            let customer = response.data.customer;
            //console.log(customer);
            if (customer === undefined || customer === null) {
                setState({
                    ...state,
                    showSalesOrderWFSlider: false,
                    showExhibitorChangeWFSlider: false,
                    showAdvertiserChangeWFSlider: false,
                    showRequestAdWFSlider: false,
                    showShowDetailSliderVO: false,
                    showAdvertisingInfoSliderVO: false,
                    showNoteDetailsSlider: false,
                    showCustomerDetailSlider: false,
                    customerFound: false
                });
            }
            else {
                setValue("formFields.customer", customer);

                setValue('categoriesListFormatted', customer.categoriesList.split(','));

                //Shows Status
                if (customer.exhibitor === 1) {
                    myShowsStatusDivStyle = 'customerstatusgreen';
                    myShowsStatusText = 'EXHIBITOR';
                }
                else if (customer.exh_Prospect === 1) {
                    myShowsStatusDivStyle = 'customerstatusyellow';
                    myShowsStatusText = 'EXH PROSPECT';
                }
                else if (customer.exhibitor === 0 && customer.exh_Prospect === 0) {
                    myShowsStatusDivStyle = 'customerstatusred';
                    myShowsStatusText = 'NO STATUS';
                }

                //Advertising Status
                if (customer.advertiser === 1) {
                    myAdvertisingStatusDivStyle = 'customerstatusgreen';
                    myAdvertisingStatusText = 'ADVERTISER';
                }
                else if (customer.adv_Prospect === 1) {
                    myAdvertisingStatusDivStyle = 'customerstatusyellow';
                    myAdvertisingStatusText = 'ADV PROSPECT';
                }
                else if (customer.advertiser === 0 && customer.adv_Prospect === 0) {
                    myAdvertisingStatusDivStyle = 'customerstatusred';
                    myAdvertisingStatusText = 'NO STATUS';
                }

                //Web Status
                if (customer.web === 1) {
                    myWebStatusDivStyle = 'customerstatusgreen';
                    myWebStatusText = 'WEB CUSTOMER';
                }
                else if (customer.web_Prospect === 1) {
                    myWebStatusDivStyle = 'customerstatusyellow';
                    myWebStatusText = 'WEB PROSPECT';
                }
                else if (customer.web === 0 && customer.web_Prospect === 0) {
                    myWebStatusDivStyle = 'customerstatusred';
                    myWebStatusText = 'NO STATUS';
                }

                //Repplace Status
                if (customer.repplace === 1) {
                    myRepplaceStatusDivStyle = 'customerstatusgreen';
                    myRepplaceStatusText = 'REPPLACE CUSTOMER';
                }
                else if (customer.repplace_Prospect === 1) {
                    myRepplaceStatusDivStyle = 'customerstatusyellow';
                    myRepplaceStatusText = 'REPPLACE PROSPECT';
                }
                else if (customer.repplace === 0 && customer.repplace_Prospect === 0) {
                    myRepplaceStatusDivStyle = 'customerstatusred';
                    myRepplaceStatusText = 'NO STATUS';
                }

                //Init Contact fields
                let contacts = response.data.contacts;

                setContactsListData(contacts);

                if (contacts.length > 0) {
                    setValue('formFields.selectedcontact.jsS_name', contacts[0].jsS_name)
                    setValue('formFields.selectedcontact.phone', contacts[0].phone)
                    setValue('formFields.selectedcontact.cell', contacts[0].cell)
                    setValue('formFields.selectedcontact.email', contacts[0].email)
                    setValue('formFields.selectedcontact.ext', contacts[0].ext)
                }

                //format company domain
                //if (getValues().formFields?.customer.domain !== null) {
                //    let myDomain = getValues().formFields?.customer.domain.replace('https://', '').replace('http://', '').replace('www.', '');
                //    myDomain = 'https://www.' + myDomain;
                //    setValue('formFields.customer.domain', myDomain);
                //}

                setIdStatusModal({
                    ...idStatusModal,
                    show: false
                });

                setState({
                    ...state,
                    selectedCustomerId: location.state.selectedCustomerId,
                    dataLoaded: true,
                    showSalesOrderWFSlider: false,
                    showExhibitorChangeWFSlider: false,
                    showAdvertiserChangeWFSlider: false,
                    showRequestAdWFSlider: false,
                    showShowDetailSliderVO: false,
                    showAdvertisingInfoSliderVO: false,
                    showCustomerDetailSlider: false,
                    customerFound: true,
                    showsStatusText: myShowsStatusText,
                    showsStatusDivStyle: myShowsStatusDivStyle,
                    advertisingStatusText: myAdvertisingStatusText,
                    advertisingStatusDivStyle: myAdvertisingStatusDivStyle,
                    webStatusText: myWebStatusText,
                    webStatusDivStyle: myWebStatusDivStyle,
                    repplaceStatusText: myRepplaceStatusText,
                    repplaceStatusDivStyle: myRepplaceStatusDivStyle,
                    selectedContact: contacts[0]
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function onContactChange() {
        var ddContacts = document.getElementById('ddContacts');
        var selectedcontact = contactsListData[ddContacts.selectedIndex];

        setValue('formFields.selectedcontact.jsS_name', selectedcontact.jsS_name)
        setValue('formFields.selectedcontact.phone', selectedcontact.phone)
        setValue('formFields.selectedcontact.cell', selectedcontact.cell)
        setValue('formFields.selectedcontact.email', selectedcontact.email)
        setValue('formFields.selectedcontact.ext', selectedcontact.ext)

        setState({
            ...state,
            showSalesOrderWFSlider: false,
            showExhibitorChangeWFSlider: false,
            showAdvertiserChangeWFSlider: false,
            showRequestAdWFSlider: false,
            showShowDetailSliderVO: false,
            showAdvertisingInfoSliderVO: false,
            showNoteDetailsSlider: false,
            selectedContact: selectedcontact
        });
    }

    function handleClick(event) {

        event.preventDefault();

        switch (event.target.id) {
            case 'btnEditExhibitor':

                break;
            case 'btnSubmitCallReport':

                break;
            default:
                break;
        }
    }

    function handleWorkflowTypeChange(e) {
        setWorkflowOpen(false);

        console.log(e.target.value);

       switch (e.target.value) {
            case 'Sales Order Entry':
                setState({
                    ...state,
                    showExhibitorChangeWFSlider: false,
                    showShowDetailSliderVO: false,
                    showAdvertisingInfoSliderVO: false,
                    showNoteDetailsSlider: false,
                    showAdvertiserChangeWFSlider: false,
                    showRequestAdWFSlider: false,
                    showSalesOrderWFSlider: true
                });

                break;
            case 'Exhibitor Change Request':
                setState({
                    ...state,
                    showSalesOrderWFSlider: false,
                    showShowDetailSliderVO: false,
                    showAdvertisingInfoSliderVO: false,
                    showNoteDetailsSlider: false,
                    showAdvertiserChangeWFSlider: false,
                    showRequestAdWFSlider: false,
                    showExhibitorChangeWFSlider: true
                });

               break;
           case 'Advertiser Change Request':
               setState({
                   ...state,
                   showSalesOrderWFSlider: false,
                   showShowDetailSliderVO: false,
                   showAdvertisingInfoSliderVO: false,
                   showNoteDetailsSlider: false,
                   showExhibitorChangeWFSlider: false,
                   showRequestAdWFSlider: false,
                   showAdvertiserChangeWFSlider: true
               });

               break;
           case 'Request Ad':
               setState({
                   ...state,
                   showSalesOrderWFSlider: false,
                   showShowDetailSliderVO: false,
                   showAdvertisingInfoSliderVO: false,
                   showNoteDetailsSlider: false,
                   showExhibitorChangeWFSlider: false,
                   showAdvertiserChangeWFSlider: false,
                   showRequestAdWFSlider: true
               });

               break;
            default:
                break;
        }

        //simulates body click to hide popover
        document.body.click();
    }

    function returnToCallingComponent() {
        //console.log(location.state.callingComponent);

        switch (location.state.callingComponent) {
            case "SearchJSS":
            case "SearchJST":
                navigate("/customersearch", { state: { selectedCustomerId: location.state.selectedCustomerId, page: location.state.page, sizePerPage: location.state.sizePerPage } });
                break;
            case "QueryBuilder":
                //pass back the query that was sent in so it pre-loads back in Query Builder
                navigate("/queryresults", { state: { query: location.state.query, resultSet: location.state.resultSet, selectedCustomerId: location.state.selectedCustomerId, page: location.state.page, sizePerPage: location.state.sizePerPage } });
                break;
            case "CallSheetGenerator":
                navigate("/call-sheet-generator", { state: { selectedContact: location.state.selectedContact, page: location.state.page, sizePerPage: location.state.sizePerPage } });
                break;
        }
    }

    function createWorkflowContent2() {
        if (userInfo.jobShopDivision === 'JSS') {
            return (
                <div onChange={handleWorkflowTypeChange}>
                    <div><button type="button" class="btn btn-link" value="Sales Order Entry" onClick={handleWorkflowTypeChange}>Sales Order Entry</button></div>
                    <div><button type="button" class="btn btn-link" value="Exhibitor Change Request" onClick={handleWorkflowTypeChange}>Exhibitor Change Request</button></div>
                    <div><button type="button" class="btn btn-link" value="Im Interested" onClick={handleWorkflowTypeChange}>I'm Interested</button></div>
                {/*    <div><input type="radio" name="wfType" value="Sales Order Entry" className="mt-1" checked={state.selectedWFType === "Sales Order Entry"} />&nbsp;<label className="form-label">Sales Order Entry</label></div>*/}
                {/*    <div><input type="radio" name="wfType" value="Exhibitor Change Request" className="mt-1" checked={state.selectedWFType === "Exhibitor Change Request"} />&nbsp;<label className="form-label">Exhibitor Change Request</label></div>*/}
                </div>
            )
        }
        else {
            return (
                <div onChange={handleWorkflowTypeChange}>
                    <div><input type="radio" name="wfType" value="Create Sales Order" className="mt-1" checked={state.selectedWFType === "Create Sales Order"} />&nbsp;<label className="form-label">Create Sales Order</label></div>
                    <div><input type="radio" name="wfType" value="Advertiser Change Request" className="mt-1" checked={state.selectedWFType === "Advertiser Change Request"} />&nbsp;<label className="form-label">Advertiser Change Request</label></div>
                    <div><input type="radio" name="wfType" value="Request Ad" className="mt-1" checked={state.selectedWFType === "Request Ad"} />&nbsp;<label className="form-label">Request Ad</label></div>
                </div>
            )
        }
    }

    const createWorkflowContent = (
        <div onChange={handleWorkflowTypeChange}>
            <div><input type="radio" name="wfType" value="Sales Order Entry" className="mt-1" checked={state.selectedWFType === "Sales Order Entry"} />&nbsp;<label className="form-label">Sales Order Entry</label></div>
            <div><input type="radio" name="wfType" value="Exhibitor Change Request" className="mt-1" checked={state.selectedWFType === "Exhibitor Change Request"} />&nbsp;<label className="form-label">Exhibitor Change Request</label></div>
        </div>
    );

    function releaseToJSS() {
        confirm({
            title: getValues().formFields?.customer.name + " will be released to JSS as an Exhibitor prospect",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                setIdStatusModal({
                    ...idStatusModal,
                    show: true
                });

                setValue('formFields.idStatus', '');
                setState({
                    ...state,
                    prospectConversionType: 'JSS'
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function releaseToJST() {
        confirm({
            title: getValues().formFields?.customer.name + " will be released to JST as an Advertiser prospect",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                setValue('formFields.idStatus', '');
                setState({
                    ...state,
                    prospectConversionType: 'JST'
                });

                setIdStatusModal({
                    ...idStatusModal,
                    show: true
                });
                
                return 0;
            } else {
                return 0;
            }
        });
    }

    function ConvertProspect() {
        if (getValues().formFields.idStatus === '') {
            toast.error('A ' + state.prospectConversionType + ' Id/Status is required.');
            return;
        }

        let postdata = {};
        postdata.company_id = location.state.selectedCustomerId;
        postdata.requesttype = 'ConvertTo' + state.prospectConversionType + 'Prospect';
        postdata.idstatus = getValues().formFields.idStatus;

        Axios.post(`/api/ConvertCustomerToProspect`, postdata
        ).then(response => {
            if (state.prospectConversionType === 'JST') {
                setState({
                    ...state,
                    advertisingStatusText: 'ADV PROSPECT',
                    advertisingStatusDivStyle: 'customerstatusyellow',
                });

                toast.success(getValues().formFields?.customer.name + ' successfully released to JST as an Advertiser prospect');
            }
            else {
                setState({
                    ...state,
                    showsStatusText: 'EXH PROSPECT',
                    showsStatusDivStyle: 'customerstatusyellow'
                });

                toast.success(getValues().formFields?.customer.name + ' successfully released to JSS as an Exhibitor prospect');
            }
        }).catch(error => {
            console.log(error);
        });

        setIdStatusModal({
            ...idStatusModal,
            show: false
        });
    }

    function EmailContact() {
        //selectedCustomer={getValues().formFields.customer} selectedContact={state.selectedContact}
        //var emailInformation = {
        //    selectedCustomer: getValues().formFields.customer,
        //    selectedContact: state.selectedContact
        //}

        var emailInformation = {
            id: '',
            sendTo: state.selectedContact.email,
            selectedCustomer: getValues().formFields.customer,
            selectedContact: state.selectedContact,
            selectedCustomerId: getValues().formFields.customer.id,
            selectedContactId: state.selectedContact.contact_id,
            userId: userInfo.userId,
            fileList: [],
            emailContent: '',
        }

        setState({
            ...state,
            emailInformation,
            showSendEmailSlider: true
        });
    }

    function handleCustomerDetailsClick() {
        setState({
            ...state,
            showCustomerDetailSlider: true
        });
    }

    async function refreshCustomer(selectedCustomer) {
        setState({
            ...state,
            dataLoaded: false
        });

        LoadCustomerInfo(selectedCustomer.id, userInfo.jobShopDivision);
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Customer Details"
                            description="Please stand by while we retrieve information for this customer"
                            type="info"
                        />
                    </div>
                    {state.dataLoaded && state.customerFound === true &&
                        <div className="row">
                            <div className="col-lg-5">
                                <h3 className="frame-heading mb-4">
                                    {getValues().formFields?.customer.name} (Customer ID: {getValues().formFields?.customer.id})&nbsp;&nbsp;&nbsp;<Button type="btn btn-submit" onClick={handleCustomerDetailsClick}>Customer Details</Button>
                                </h3>
                                <div className="frame-sub-heading mt-n1">
                                    {getValues().formFields?.customer.street1}, {getValues().formFields?.customer.city}, {getValues().formFields?.customer.state} {getValues().formFields?.customer.zip}
                                </div>
                            </div>
                            <div className="col-sm-1 col-md-1 col-lg-1 text-center">
                                {/*<Popover*/}
                                {/*    content={createWorkflowContent2}*/}
                                {/*    title="Select Type of Workflow"*/}
                                {/*    trigger="click"*/}
                                {/*    open={workflowOpen}*/}
                                {/*    onOpenChange={handleOpenChange}*/}
                                {/*>*/}
                                {/*    <Button type="primary">Create Workflow</Button>*/}
                                {/*</Popover>*/}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 float-right">
                                <table width="100%">
                                    <tbody>
                                        <tr className="frame-sub-heading">
                                            <td className="alignLeft">Shows&nbsp;Status<span className="form-data-label fw-bold">{getValues().formFields?.customer.jsS_sales_rep !== null ? 'JSS Rep: ' + getValues().formFields?.customer.jsS_sales_rep : "No JSS Rep Assigned"}</span></td>
                                            <td className="alignLeft">Advertising&nbsp;Status<span className="form-data-label fw-bold">{getValues().formFields?.customer.jsT_sales_rep !== null ? 'JST Rep: ' + getValues().formFields?.customer.jsT_sales_rep : "No JST Rep Assigned"}</span></td>
                                            <td>Web Status<span className="form-data-label">&nbsp;</span></td>
                                            <td>RepPlace Status<span className="form-data-label">&nbsp;</span></td>
                                        </tr>
                                        <tr className="frame-customer-type">
                                            <td>
                                                <label className={`alignLeft mt-2 ${state.showsStatusDivStyle}`}>{state.showsStatusText}</label>
                                            </td>
                                            <td className="alignLeft">
                                                <label className={`alignLeft mt-2 ${state.advertisingStatusDivStyle}`}>{state.advertisingStatusText}</label>
                                            </td>
                                            <td className="alignLeft">
                                                <label className={`alignLeft mt-2 ${state.webStatusDivStyle}`}>{state.webStatusText}</label>
                                            </td>
                                            <td className="alignLeft">
                                                <label className={`alignLeft mt-2 ${state.repplaceStatusDivStyle}`}>{state.repplaceStatusText}</label>
                                            </td>
                                        </tr>
                                        <tr className="frame-customer-type">
                                            <td className="alignLeft">
                                                {state.advertisingStatusText === 'NO STATUS' && userInfo.userType == 'admin' && <button className="btn btn-link fw-bold" onClick={releaseToJST}>Release To JST</button>}
                                            </td>
                                            <td className="alignLeft">
                                                {state.showsStatusText === 'NO STATUS' && userInfo.userType == 'admin' && <button className="btn btn-link fw-bold" onClick={releaseToJSS}>ReleaseTo JSS</button>}
                                            </td>
                                            <td className="alignLeft"></td>
                                            <td className="alignLeft"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-12">
                                <hr />
                                <div>
                                    {state.dataLoaded &&
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3">
                                                    {contactsListData.length > 0 &&
                                                        <div>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Contacts:&nbsp;</th>
                                                                        <td>
                                                                            <section>
                                                                                <select id="ddContacts" onChange={onContactChange}>
                                                                                    {contactsListData.map((contact) => <option key={contact.contact_id} value={contact.contact_id}>{contact.jsS_name} ({contact.contact_type})</option>)}
                                                                                </select>
                                                                            </section>
                                                                        </td>
                                                                    </tr>
                                                                    <tr><th>Name:&nbsp;</th><td>{getValues().formFields?.selectedcontact.jsS_name}</td></tr>
                                                                    <tr><th>Phone:&nbsp;</th><td>{getValues().formFields?.selectedcontact.phone}&nbsp;&nbsp;&nbsp;{getValues().formFields?.selectedcontact.ext !== '' && getValues().formFields?.selectedcontact.ext !== null ? <span><b>Ext: </b>{getValues().formFields?.selectedcontact.ext}</span> : '' }</td></tr>
                                                                    <tr><th>Cell:&nbsp;</th><td>{getValues().formFields?.selectedcontact.cell}</td></tr>
                                                                    {/*<tr><th>Email:&nbsp;</th><td><a href={getValues().formFields?.selectedcontact.email ? "mailto:" + getValues().formFields?.selectedcontact.email + "?subject=" + location.state.selectedCustomerId : "mailto:" + getValues().formFields?.selectedcontact.email}>{getValues().formFields?.selectedcontact.email}</a></td></tr>*/}
                                                                    <tr><th>Email:&nbsp;</th><td><button type="button" class="btn btn-link btn-lg" onClick={EmailContact}>{getValues().formFields?.selectedcontact.email}</button></td></tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                    {contactsListData.length === 0 &&
                                                        <div>
                                                            <span style={{ color: 'red' }}><h5>No Contacts Found</h5></span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3">
                                                    <strong>Service Categories</strong><br />
                                                    {getValues().categoriesListFormatted?.map((cat, index) => {
                                                        return <div>{cat}</div>;
                                                    })}
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6">
                                                    <table>
                                                        <tbody>
                                                    <tr><th style={{ verticalAlign: 'top' }}>Web Site:&nbsp;</th><td><a target='_blank' rel='noopener noreferrer' href={getValues().formFields?.customer.domain ? `https://${getValues().formFields?.customer.domain.replace('https://', '').replace('http://', '')}`: ''}>{getValues().formFields?.customer.domain}</a></td></tr>
                                                            <tr><th>Source:&nbsp;</th><td>{getValues().formFields?.customer.sourceList}</td></tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="mt-2">
                                                        <strong>Description</strong><br />
                                                        {getValues().formFields?.customer.description}
                                                    </div>
                                                    <div className="mt-4">
                                                        <span style={location.state.callingComponent === 'SearchJSS' || location.state.callingComponent === 'SearchJST' ? {} : { display: 'none' }}><Button className="btn btn-submit" onClick={returnToCallingComponent}>Back to Search Results</Button></span>
                                                        <span style={location.state.callingComponent === 'QueryBuilder' ? {} : { display: 'none' }}><Button className="btn btn-submit" onClick={returnToCallingComponent}>Back to Query Builder Results</Button></span>
                                                        <span style={location.state.callingComponent === 'CallSheetGenerator' ? {} : { display: 'none' }}><Button className="btn btn-submit" onClick={returnToCallingComponent}>Back to Call Sheet Generator</Button></span>
                                                    </div>
                                                </div>
                                                

                                                <div className="col-lg-12 mt-3">
                                                    <Tabs defaultActiveKey='tab-notes' tabPosition='top' type='card'>
                                                        <TabPane tab='Contacts' key='tab-contacts'>
                                                            <CustomerContacts selectedCustomerId={location.state.selectedCustomerId} contacts={contactsListData} selectedCustomer={getValues().formFields.customer} />
                                                        </TabPane>
                                                        <TabPane tab='Notes' key='tab-notes'>
                                                            <CustomerNotes selectedCustomerId={location.state.selectedCustomerId} />
                                                        </TabPane>

                                                        <TabPane tab='Show Detail Info' key='tab-shows'>
                                                            <ShowDetailInfo selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} showShowDetailSliderVO={false} />
                                                        </TabPane>

                                                        <TabPane tab='Advertising Info' key='tab-ads'>
                                                            <AdvertisingInfo selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} />
                                                        </TabPane>

                                                        <TabPane tab='Tasks' key='tab-tasks'>
                                                            <CustomerTasks selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} />
                                                        </TabPane>
                                                    </Tabs>

                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {state.dataLoaded && state.customerFound === false &&
                        <div className="col-lg-12">
                            <h3 className="frame-heading mb-4">
                                Selected Company Not Found
                            </h3>
                            <div className="frame-sub-heading mt-n1">
                                Contact Your System Administrator
                            </div>
                        </div>
                    }
                </Spin>
            </div>

            {state.showSalesOrderWFSlider &&
                <JSS_SalesOrderWFSlider showPane={state.showSalesOrderWFSlider} selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} hidePane={() => setState({ ...state, showSalesOrderWFSlider: false })} />
            }
            {state.showExhibitorChangeWFSlider &&
                <JSS_ExhibitorChangeWFSlider showPane={state.showExhibitorChangeWFSlider} selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} hidePane={() => setState({ ...state, showExhibitorChangeWFSlider: false })} />
            }
            {state.showAdvertiserChangeWFSlider &&
                <JST_AdvertiserChangeWFSlider showPane={state.showAdvertiserChangeWFSlider} selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} />
            }
            {state.showRequestAdWFSlider &&
                <JST_RequestAdWFSlider showPane={state.showRequestAdWFSlider} selectedCustomerId={location.state.selectedCustomerId} selectedCustomerName={getValues().formFields?.customer.name} />
            }
            {state.showSendEmailSlider &&
                <SendEmailSlider emailInformation={state.emailInformation} showPane={state.showSendEmailSlider} hidePane={() => setState({ ...state, showSendEmailSlider: false })} />
            }
            {state.showCustomerDetailSlider &&
                <CustomerDetailSlider showPane={state.showCustomerDetailSlider} refreshCustomer={refreshCustomer} selectedCustomer={getValues().formFields.customer} selectedCustomerId={location.state.selectedCustomerId} hidePane={() => setState({ ...state, showCustomerDetailSlider: false })} />
            }
            <Modal
                visible={idStatusModal.show}
                title="Convert To Prospect Utility"
                width={500}
                footer={[
                    <button className="btn btn-submit" onClick={() => ConvertProspect()} >
                        Convert To {state.prospectConversionType} Prospect
                    </button>,
                    <button className="btn btn-outline-primary float-right" onClick={() => { setIdStatusModal({ ...idStatusModal, show: false }); }} >
                        Cancel
                    </button>
                ]}
            >
                <div className="row">
                    <label className="form-label">Please enter the {state.prospectConversionType} Id/Status to flag the {state.prospectConversionType} prospect with.</label>
                    <input type="text" {...register("formFields.idStatus")} className="form-control-custom" />
                </div>
            </Modal>
        </div>
    );
}